import { useRef, useState } from "react"
import styled from "styled-components"

import { Grow, Paper, Popper } from "@material-ui/core"
import { useOnClickOutside } from "usehooks-ts"

import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colors } from "src/ui/colors"
import { TFilter } from "src/ui/Filter/Filter"
import { spacing } from "src/ui/spacing"

export function AddFilter({
  filters,
  onAddFilter,
  disabled,
}: {
  filters: TFilter[]
  onAddFilter: (filter: TFilter) => void
  disabled: boolean
}) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslate()

  useOnClickOutside(popperRef, (e) => {
    const eventTarget = e.target as Element

    if (!anchorRef.current?.contains(eventTarget)) {
      setOpen(false)
    }
  })

  return (
    <div>
      <StyledButton
        ref={anchorRef}
        onClick={() => setOpen((prev) => !prev)}
        disabled={disabled}
      >
        {t(langKeys.add_filter)} +
      </StyledButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        ref={popperRef}
        style={{ marginBlock: spacing.XS }}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <DropdownContentBox>
              <StyledSelect>
                <ItemsList>
                  {filters.map((filter) => (
                    <Option
                      key={filter.label}
                      onClick={() => {
                        setOpen((prev) => !prev)
                        onAddFilter(filter)
                      }}
                      tabIndex={-1}
                    >
                      {filter.label}
                    </Option>
                  ))}
                </ItemsList>
              </StyledSelect>
            </DropdownContentBox>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  font-size: 0.875rem;
  background-color: transparent;
  padding: 2px ${spacing.XS};
  border: 1px dashed ${colors.divider};
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s;

  &:hover {
    border-style: solid;
    background-color: ${colors.backgroundGrayV3};
  }

  &:disabled {
    background-color: ${colors.divider};
    cursor: auto;
  }
`

const DropdownContentBox = styled(Paper)`
  min-width: 200px;
`

const StyledSelect = styled.div`
  padding: ${spacing.S} 0;
  width: 300px;
`

const ItemsList = styled.div`
  max-height: 300px;
  overflow: auto;
`

const Option = styled.div`
  padding: 4px ${spacing.S} 4px ${spacing.M};

  &:hover {
    background-color: ${colors.backgroundGrayV2};
  }
`
